import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { RichText, RichTextBlock } from 'prismic-reactjs';

import { graphql } from 'gatsby';

import LayoutFrame from '../components/layout/LayoutFrame';
import { Typography } from '@nelnet/unifi-components-react';
import Hero from '../components/layout/Hero';
import CalloutGroup from '../components/layout/CalloutGroup';
import ContentSummary from '../components/layout/ContentSummary';
import DescriptionList from '../components/layout/DescriptionList';

interface IGuidelinesProps {
  data: {
    prismic: {
      allGuideliness: {
        edges: [
          {
            node: {
              _meta: {
                uid: string;
              };
              image: {
                url: string;
                alt: string;
              };
              title: RichTextBlock[];
              page_summary: RichTextBlock[];
              content_title: RichTextBlock[];
              component_title: RichTextBlock[];
              primary_page_color: string;
              page_font_color: string;
            };
          },
        ];
      };
      allGuideline_pages: {
        edges: [
          {
            node: {
              _meta: {
                uid: string;
                tags: string;
              };
              image: {
                url: string;
                alt: string;
              };
              title: string;
              header_content: string;
              body: {
                primary: {
                  additional_title: RichTextBlock[];
                  additional_description: RichTextBlock[];
                };
              };
            };
          },
        ];
      };
      allComponents: {
        edges: [
          {
            node: {
              _meta: {
                uid: string;
                tags: string;
              };
              title: string;
              description: string;
            };
          },
        ];
      };
      allFooters: {
        edges: [
          {
            node: {
              title: RichTextBlock[];
              subtitle: RichTextBlock[];
              button_text: RichTextBlock[];
              button_style: 'text' | 'outlined' | 'contained';
              button_link: {
                text: string;
              };
            };
          },
        ];
      };
    };
  };
}

const Guidelines: React.FunctionComponent<IGuidelinesProps> = ({ data }) => {
  // Required check for no data being returned
  const doc = data.prismic.allGuideliness.edges.slice(0, 1).pop();
  const footerDoc = data.prismic.allFooters.edges.slice(0, 1).pop();

  const uid = doc.node._meta.uid;
  const slug = uid === 'home' ? '/' : `/${uid}/`;
  const title = doc.node.title;

  if (!doc) {
    return (
      <LayoutFrame currPath={slug}>
        <Helmet>
          <title>{RichText.asText(title)}</title>
        </Helmet>
        <Typography variant="h2" margin={{ bottom: 3 }}>
          {RichText.asText(title)}
        </Typography>
      </LayoutFrame>
    );
  }

  const { page_summary } = doc.node;
  const icon_url = doc.node.image.url;
  const icon_alt = doc.node.image.alt;
  const page_font_color = doc.node.page_font_color.slice(-7);
  const page_color = doc.node.primary_page_color.slice(-7);

  const content_title = doc.node.content_title;
  const content_fields = data.prismic.allGuideline_pages.edges;

  const component_title = doc.node.component_title;
  const list_items = data.prismic.allComponents.edges;

  const { button_text, button_style } = footerDoc.node;
  const footer_title = footerDoc.node.title;
  const footer_subtitle = footerDoc.node.subtitle;
  const button_link = footerDoc.node.button_link[0].text;

  return (
    <LayoutFrame currPath={slug}>
      <Helmet>
        <title>{RichText.asText(title)}</title>
      </Helmet>
      <Hero
        icon_url={icon_url}
        icon_alt={icon_alt}
        title={title}
        subtitle={page_summary}
        page_color={page_color}
        page_font_color={page_font_color}
      ></Hero>
      <ContentSummary
        content_title={content_title}
        content_fields={content_fields}
        parent_slug={uid}
      ></ContentSummary>
      <DescriptionList
        title={component_title}
        list_items={list_items}
        parent_slug={uid}
      ></DescriptionList>
      <CalloutGroup
        title={footer_title}
        subtitle={footer_subtitle}
        button_text={button_text}
        button_style={button_style}
        button_url={button_link}
      ></CalloutGroup>
    </LayoutFrame>
  );
};

export default Guidelines;

export const query = graphql`
  query GuidelinesQuery {
    prismic {
      allGuideliness {
        edges {
          node {
            _meta {
              uid
              type
              tags
            }
            image
            title
            page_summary
            content_title
            component_title
            primary_page_color
            page_font_color
          }
        }
      }
      allGuideline_pages(sortBy: title_ASC, first: 50) {
        edges {
          node {
            _meta {
              uid
              tags
            }
            image
            title
            header_content
            body {
              ... on PRISMIC_Guideline_pageBodyAdditional_content {
                primary {
                  additional_description
                  additional_title
                }
              }
            }
          }
        }
      }
      allComponents(sortBy: title_ASC, first: 50) {
        edges {
          node {
            _meta {
              uid
              tags
            }
            title
            description
          }
        }
      }
      allFooters {
        edges {
          node {
            title
            subtitle
            button_text
            button_style
            button_link
          }
        }
      }
    }
  }
`;
